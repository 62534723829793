<template>
  <div class="Help">
    <div class="header">
      <div class="header-text">
        <h2>下载客户端</h2>
      </div>
    </div>
    <div class="Help-box">
      <div class="Help-content">
       
      </div>
    </div>
  </div>
</template>

<script>
import unifildStyle from '../../assets/css/help/unifildStyle.less'
export default {
  components: {},
  data() {
    return {
    };
  },
  created() {},
  mounted() {},
  methods: {
    getSearch(k) {
      // 获取地址栏内容
      var str = window.location.href.split("?")[1];
      // 解码成中文
      str = decodeURIComponent(str);
      var arr = str.split("&");
      var obj = {};
      // 获取键和值
      arr.forEach(function (v, i) {
        var key = v.split("=")[0];
        var value = v.split("=")[1];
        obj[key] = value;
      });
      return obj[k];
    }
  },
  watch: {},
  computed: {},
};
</script>

